import { useLocation } from "@remix-run/react";
import React, { useContext } from "react";
import { useSession } from "~/contexts/session";
import { useRequestInfo } from "~/hooks/request-info";

interface ValidationFunction {
	(user: any): boolean; // Adjust the type of 'user' based on your actual user object
}

interface FeatureFlags {
	[name: string]:
		| {
				route?: string;
				roles?: string[];
				domains?: string[];
				validations?: ValidationFunction[];
		  }
		| boolean
		| undefined;
}

const FeatureFlagsContext = React.createContext<FeatureFlags | undefined>(
	undefined,
);

interface FeatureProps {
	name?: string;
	children: React.ReactNode;
}

function Feature({ name, children }: FeatureProps) {
	const requestInfo = useRequestInfo();
	const location = useLocation();
	const { user } = useSession();
	const featureFlags = useContext<FeatureFlags | undefined>(
		FeatureFlagsContext,
	);

	if (!featureFlags) {
		console.error("FeatureFlagsContext not provided!");
		return null;
	}

	if (!name) {
		return null;
	}

	const feature = featureFlags[name];

	if (!feature) {
		console.error(`Feature '${name}' not found in featureFlags!`);
		return null;
	}

	const { route, domains, validations } = feature as {
		route?: string;
		roles?: string[];
		domains?: string[];
		validations?: ValidationFunction[];
	};

	console.debug(`Checking feature '${name}'`);
	console.debug(`Route: ${route}`);
	console.debug(`Domains: ${domains}`);

	const isEnabled =
		(!route || location.pathname.includes(route)) && // Route Match
		// (!roles || roles.includes(user.currentRole)) && // Role Check
		(!domains || domains.includes(requestInfo.origin)) && // Domain Check
		(!validations ||
			validations.every((validation: ValidationFunction) => validation(user))); // Checks logic

	console.debug(`Feature '${name}' status ${isEnabled}`);

	return isEnabled ? <>{children}</> : null;
}

// Feature Provider to be used at a higher level
interface FeatureProviderProps {
	featureFlags: FeatureFlags;
	children: React.ReactNode;
}

function FeatureProvider({ featureFlags, children }: FeatureProviderProps) {
	return (
		<FeatureFlagsContext.Provider value={featureFlags}>
			{children}
		</FeatureFlagsContext.Provider>
	);
}

export { Feature, FeatureProvider };

export const ToggleDisplay = ({ use = true, show, children }: any) => {
	if (use) {
		return show ? <>{children}</> : null;
	}
	return <>{children}</>;
};
